(function () {
    //'use strict';

    angular
        .module('mobilityexchangeApp')
        .controller('MemberContactsDeleteController', MemberContactsDeleteController);

    MemberContactsDeleteController.$inject = ['$uibModalInstance', 'entity', 'MemberContacts'];

    function MemberContactsDeleteController($uibModalInstance, entity, MemberContacts) {
        var vm = this;

        vm.memberContacts = entity;
        vm.clear = clear;
        vm.confirmDelete = confirmDelete;
        vm.activeQual = 0;
        vm.activeControl = 0;
        vm.activeVolunteerGroups = 0;
        vm.activeVolunteerEngagements = 0;

        for (i = 0; i < vm.memberContacts.contactQualifications.length; i++) {
            if (vm.memberContacts.contactQualifications[i].expDate > Date.now()) {
                vm.activeQual++;
            }
        }

         for (i = 0; i < vm.memberContacts.contactControls.length ; i++){
             if (vm.memberContacts.contactControls[i].displayFlag
                || vm.memberContacts.contactControls[i].adminFlag){
                    vm.activeControl++;
                }
        }

        for (i = 0; i < vm.memberContacts.volunteerGroups.length; i++) {
            if (vm.memberContacts.volunteerGroups[i].expDate > Date.now()) {
                vm.activeVolunteerGroups++;
            }
        }

        for (i = 0; i < vm.memberContacts.volunteerEngagements.length; i++) {
            // The expiry date is in the ISO8601 format (e.g. 2035-01-01 23:02:27)
            if (vm.memberContacts.volunteerEngagements[i].expDate && (new Date(vm.memberContacts.volunteerEngagements[i].expDate) > Date.now())) {
                vm.activeVolunteerEngagements++;
            }
        }

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function confirmDelete(id) {
            MemberContacts.delete({ id: id },
                function () {
                    $uibModalInstance.close(true);
                });
        }
    }
})();
